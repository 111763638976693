var api_host = 'http://staging.andromeda.perseo.hr/api/';
var testing_url = 'https://app.perseo.hr/?token=';

var login_page = '/login';

$(document).ready(function() {

    /***************** Login form ajax ******************/
    $('#login-form-submit-button').click(function (e) {
        $(this).closest('form').submit();
    });

    /***************** Contact form ajax ******************/

    $('.js-contact-form').on('submit',function(e){
        e.preventDefault();

        $('.form-group').removeClass('has-error');

        var $form = $(this);
        var url = $form.attr('action');
        var formData = new FormData($form[0]);
        var $response_success = $form.parent().find('.js-form-response-success');
        var $response_failed = $form.parent().find('.js-form-response-failed');

        $.ajax({
            url : url,
            type : 'POST',
            data : formData,
            dataType : 'json',
            contentType: false,
            processData: false,
            success : function(data) {
                if (data.status == 1) {
                    $response_success.show();
                    $form.addClass('is-send');
                    $response_success.text('Ihre Nachricht wurde gesendet. Vielen Dank.').show();
                    $form.find('.pso-contact-form-input').addClass('deactivated');
                } else {
                    $response_failed.show();
                    if(data.errors != undefined) {
                        for (var error in data.errors) {
                            $form.find('.form-group.' + error).addClass('has-error');
                        }
                    }
                }
            },
            error : function(XHR, textStatus, errorThrown) {
                $response_failed.show();
            }
        });

    });


    /***************** Tooltips ******************/
    $('[data-toggle="tooltip"]').tooltip();


    /***************** Click blockade ******************/

    $(".click-allow-events-js").click(function(){
        $(this).children().removeClass('prevent-pointer-events');
    });


    /***************** js links ******************/

    $('.js-click-link').click(function () {
        console.log(this);
        var url = $(this).data('url');
        if(url) {
            if($(this).data('target') == '_blank') {
                window.open(url);
            } else {
                window.location.href = url;
            }
        }
    });



    $('.mdl-textfield__input.required')
        .focusout(function () {
            $(this).attr('required', true);
        })

    $('.js-show-test-description').click(function () {
        $(this).closest('.pso-card-wide').find('.js-test-desscription').slideToggle();
    })

    $('.datepicker').datepicker();

});


$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});



/***************** Login check ******************/

function logincheck() {
    $.get(api_host + 'logincheck',{"perseo-session": localStorage.getItem('session_token')})
        .done(function(result){
            if(result.response.status == 0) {
                window.location.href = login_page;
            }
        })
        .fail(function () {
            window.location.href = login_page;
        });
}



/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

